<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Portfolios" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
      </div>
      <md-table
        v-model="portfolios.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start"></div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
          <md-table-cell md-label="User">{{
            item.user.first_name + " " + item.user.last_name
          }}</md-table-cell>
          <md-table-cell md-label="Approval Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >

          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item @click="setPortfolio(item)">Preview</md-menu-item>
                <div v-if="hasPermission">
                  <md-menu-item
                    v-if="
                      ['disapproved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeStatus(item.id, 'approved')"
                    >Approve</md-menu-item
                  >
                  <md-menu-item
                    v-if="
                      ['approved', 'pending'].includes(item.approval_status)
                    "
                    @click="changeStatus(item.id, 'disapproved')"
                    >Disapprove</md-menu-item
                  >
                  <md-menu-item
                    v-if="item.promote_count == 0"
                    @click="promote(item)"
                    >Promote <i class="material-icons">arrow_upward</i>
                  </md-menu-item>
                  <md-menu-item v-else @click="promote(item, 'update')"
                    >Update Promotion
                  </md-menu-item>
                  <md-menu-item @click="remove(item.id)">Delete</md-menu-item>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="portfolios.mdPage"
        :records="portfolios.mdCount"
        :per-page="portfolios.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
    <md-dialog :md-active.sync="show">
      <md-dialog-title>Portfolio Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="portfolio">
        <div class="item">
          <strong>Title</strong>
          <span> {{ portfolio.title }}</span>
        </div>
        <div class="item">
          <strong>Description</strong>
          <span v-html="portfolio.description"> </span>
        </div>
        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${portfolio.phone_ext + portfolio.phone}`">
            {{ portfolio.portfolio_phone_ext + portfolio.portfolio_phone }}</a
          >
        </div>

        <div class="item">
          <strong>Date Created</strong>
          <span> {{ portfolio.created_at | formatDate }}</span>
        </div>

        <div class="item">
          <strong>Approval Status</strong>
          <div
            class="badge"
            :class="{
              success: portfolio.approval_status == 'approved',
              danger: portfolio.approval_status == 'disapproved',
            }"
          >
            {{ portfolio.approval_status }}
          </div>
        </div>

        <div class="media" v-if="portfolio.media">
          <div
            class="display"
            v-for="image in portfolio.media.data"
            :key="image"
          >
            <img
              :src="image.media.url"
              onerror="this.src='https://via.placeholder.com/150x150?text=No%20Image'"
              alt="Display Image"
            />
          </div>
        </div>

        <hr />
        <div>
          <h4>User information</h4>
        </div>
        <div class="item">
          <strong>Full name</strong>
          <span> {{ portfolio.user.name }}</span>
        </div>

        <div class="item">
          <strong>Email Address</strong>
          <span> {{ portfolio.user.email }}</span>
        </div>

        <div class="item">
          <strong>Phone Number</strong>
          <a :href="`tel:${portfolio.user.phone_ext + portfolio.user.phone}`">
            {{ portfolio.user.phone_ext + portfolio.user.phone }}</a
          >
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("user");
export default {
  name: "User",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
      user: null,
      portfolio: null,
      show: false,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions([
      "getPortfolios",
      "updatePortfolioStatus",
      "deletePortfolio",
    ]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getPortfolios(data);
    },
    setPortfolio(portfolio) {
      this.show = true;
      this.portfolio = portfolio;
    },
    async submitSearch() {
      await this.getPortfolios({ keyword: this.search });
    },
    async changeStatus(id, status) {
      try {
        this.updating = true;
        await this.updatePortfolioStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Portfolio Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async promote(item, mode = "create") {
      this.$eventBus.$emit("promote", {
        item_type: this.promotions.PORTFOLIO,
        item_id: item.id,
        title: item.title,
        promote: item.promote,
        mode,
      });
    },
    setUser(user) {
      this.show = true;
      this.user = user;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deletePortfolio(id);
          this.deleting = false;
          this.toast("success", "Portfolio Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
  },
  created() {},
  mounted() {
    this.getPortfolios();
  },
  computed: {
    ...mapGetters(["portfolios", "loading"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
